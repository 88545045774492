















import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
import Header from "./components/container-header/index.vue";
import SiderMenu from "./components/container-sider-menu/index.vue";

interface Vdata {}

@Component({
  components: {
    Header,
    SiderMenu,
  },
})
export default class Container extends Vue {
  // data
  private data: Vdata = {};

  @Watch("$route", { immediate: true, deep: true })
  routeChange(newVal: any, oldVal: any) {
    this.checToken();
  }

  // methods 检测 路由
  private checToken(): void {
    this.axios({
      method: "post",
      url: `${this.$store.state.common.loginServer}auths/menus/S04SU25`,
      // headers: { 'token': this.$store.state.token, 'system': 'S04SU03' }
    }).then((res: any) => {});
  }

  // mounted
  private mounted(): void {}
}
