





















































import { Component, Vue, Prop, Watch, Inject, Provide, Emit } from 'vue-property-decorator';

  interface Vdata {

  }


@Component({
  components: {

  },
})
export default class ContainerSiderMenu extends Vue {

  // data
  private data: Vdata = {
      name: 'container-sider-menu',
  }

  // methods
  logoout(): void{

  }
}
