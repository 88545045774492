




















import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";

interface Vdata {
  loginname: string;
  name: string;
}

@Component({
  components: {},
})
export default class ContainerHeader extends Vue {
  // data
  private data: Vdata = {
    name: "container-header",
    loginname: "",
  };

  @Watch("$store.state.userInfo.loginName", { immediate: true, deep: true })
  onLoginNameChange(newValue: string) {
    let name = newValue;
    this.data.loginname = name.substr(0, name.length > 9 ? 9 : name.length);
  }

  // methods logoout 退出
  logoout(this: any): void {
    this.axios
      .get(this.$store.state.common.loginServer + "auths/lginOut")
      .then((res: any) => {
        localStorage.clear();
        window.close();
        window.location.href = "about:blank";
      });
  }
}
